import { ReactComponent as AlgoTrade } from "../icons/algoTrade.svg";
import { ReactComponent as Setting } from "../icons/setting.svg";
import { ReactComponent as User } from "../icons/user.svg";
import { ReactComponent as Bell } from "../icons/bell.svg";
import { ReactComponent as Logout } from "../icons/logout.svg";
import { ReactComponent as Mining } from "../icons/mining.svg";
import { ReactComponent as Membership } from "../icons/membership.svg";
import { ReactComponent as DataCenter } from "../icons/datacenter.svg";

import { ReactComponent as AlgoTrade_fill } from "../icons/algoTrade_fill.svg";
import { ReactComponent as Setting_fill } from "../icons/setting_fill.svg";
import { ReactComponent as Mining_fill } from "../icons/mining_fill.svg";
import { ReactComponent as DataCenter_fill } from "../icons/datacenter_fill.svg";

import { ReactComponent as Bybit } from "../icons/bybit.svg";
import { ReactComponent as Binance } from "../icons/binance.svg";
import { ReactComponent as ETH } from "../icons/eth.svg";
import { ReactComponent as BTC } from "../icons/btc.svg";
import { ReactComponent as BTC_STROKE } from "../icons/btc_stroke.svg";
import { ReactComponent as ETH_STROKE } from "../icons/eth_stroke.svg";

import { ReactComponent as Arrow } from "../icons/arrow.svg";
import { ReactComponent as Shortcut } from "../icons/shortcut.svg";
import { ReactComponent as Dollar } from "../icons/dollar.svg";
import { ReactComponent as Shield } from "../icons/shield.svg";
import { ReactComponent as Line } from "../icons/line.svg";

import { ReactComponent as Bybit_white } from "../icons/bybit_white.svg";
import { ReactComponent as Binance_white } from "../icons/binance_white.svg";
import { ReactComponent as Ig_white } from "../icons/ig_white.svg";

import { ReactComponent as Arrow_left } from "../icons/arrow_left.svg";
import { ReactComponent as Arrow_right } from "../icons/arrow_right.svg";
import { ReactComponent as Close } from "../icons/close.svg";

import { ReactComponent as Tooltip } from "../icons/info_stroke.svg";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as Share } from "../icons/share.svg";

import { ReactComponent as Stop } from "../icons/stop.svg";
import { ReactComponent as Play } from "../icons/play.svg";
import { ReactComponent as Copy } from "../icons/copy.svg";

import { ReactComponent as Profile } from "../icons/profile.svg";
import { ReactComponent as Telegram } from "../icons/telegram.svg";
import { ReactComponent as Key } from "../icons/key.svg";
import { ReactComponent as Withdawal } from "../icons/wallet.svg";

import { ReactComponent as Star } from "../icons/star.svg";
import { ReactComponent as Edit } from "../icons/edit.svg";

import { ReactComponent as Deposit } from "../icons/deposit.svg";
import { ReactComponent as Withdraw } from "../icons/withdraw.svg";
import { ReactComponent as History } from "../icons/history.svg";
import { ReactComponent as Warning } from "../icons/warning.svg";

import { ReactComponent as Success } from "../icons/success.svg";
import { ReactComponent as Error } from "../icons/error.svg";

import { ReactComponent as Upload_img } from "../icons/upload_img.svg";
import { ReactComponent as Calendar } from "../icons/calendar.svg";

import { ReactComponent as Withdrawn } from "../icons/withdrawn.svg";
import { ReactComponent as DepositVolume } from "../icons/depositVolume.svg";
import { ReactComponent as Trend } from "../icons/trend.svg";

import { ReactComponent as Submachine } from "../icons/guns/submachine.svg";
import { ReactComponent as Pistol } from "../icons/guns/pistol.svg";
import { ReactComponent as Rifle } from "../icons/guns/rifle.svg";
import { ReactComponent as Flintlock } from "../icons/guns/flintlock.svg";
import { ReactComponent as GrenadeLauncher } from "../icons/guns/grenadeLauncher.svg";
import { ReactComponent as Gatling } from "../icons/guns/gatling.svg";
import { ReactComponent as Drone } from "../icons/guns/drone.svg";

import { ReactComponent as EyeOpen } from "../icons/eye_open.svg";
import { ReactComponent as EyeClose } from "../icons/eye_close.svg";

import { ReactComponent as Sort } from "../icons/sort.svg";

import { ReactComponent as Change } from "../icons/change.svg";
import { ReactComponent as Link } from "../icons/link.svg";
import { ReactComponent as Redirect } from "../icons/redirect.svg";

import { ReactComponent as DataCenter_News } from "../icons/datacenter/news.svg";
import { ReactComponent as DataCenter_Announcement } from "../icons/datacenter/announcement.svg";
import { ReactComponent as DataCenter_Chart } from "../icons/datacenter/chart.svg";
import { ReactComponent as DataCenter_Diary } from "../icons/datacenter/diary.svg";
import { ReactComponent as DataCenter_List } from "../icons/datacenter/list.svg";
import { ReactComponent as DataCenter_BarChart } from "../icons/datacenter/barchart.svg";
import { ReactComponent as DataCenter_RadarChart } from "../icons/datacenter/radar.svg";
import { ReactComponent as DataCenter_UnderConstruction } from "../icons/datacenter/underconstruction.svg";
import { ReactComponent as DataCenter_Coin } from "../icons/datacenter/coin.svg";
import { ReactComponent as DataCenter_Analytics } from "../icons/datacenter/analytics.svg";

import { ReactComponent as Member_Detail } from "../icons/member/detail.svg";
import { ReactComponent as Member_Buy } from "../icons/member/buy.svg";
import { ReactComponent as Member_Sell } from "../icons/member/sell.svg";
import { ReactComponent as Member_Empowerment } from "../icons/member/empowerment.svg";
import { ReactComponent as Member_Earn } from "../icons/member/earn.svg";
import { ReactComponent as Member_Value } from "../icons/member/value.svg";
import { ReactComponent as Member_Mindmap } from "../icons/member/mindmap.svg";
import { ReactComponent as Member_Member } from "../icons/member/member.svg";
import { ReactComponent as Member_Member_fill } from "../icons/member/member_fill.svg";

import { ReactComponent as Questhub_Rocket } from "../icons/questhub/rocket.svg";
import { ReactComponent as Questhub_Rocket_fill } from "../icons/questhub/rocket_fill.svg";
import { ReactComponent as Questhub_Medal } from "../icons/questhub/medal.svg";
import { ReactComponent as Questhub_InProgress } from "../icons/questhub/inprogress.svg";
import { ReactComponent as Questhub_Completed } from "../icons/questhub/completed.svg";

import { ReactComponent as Tutorial_Teaching } from "../icons/tutorial/teaching.svg";
import { ReactComponent as Tutorial_Help } from "../icons/tutorial/help.svg";

import { ReactComponent as Scroll } from "../icons/scroll.svg";
import { ReactComponent as Terms } from "../icons/terms.svg";

import { ReactComponent as Positive } from "../icons/emoji/positive.svg";
import { ReactComponent as Neutral } from "../icons/emoji/neutral.svg";
import { ReactComponent as Negative } from "../icons/emoji/negative.svg";
import { ReactComponent as Unsubscribe } from "../icons/unsubscribe.svg";
import { ReactComponent as Subscribe } from "../icons/subscribe.svg";

import { ReactComponent as Point } from "../icons/point.svg";

export default {
  AlgoTrade,
  AlgoTrade_fill,
  Setting,
  Setting_fill,
  Mining,
  Mining_fill,
  User,
  Bell,
  Bybit,
  Bybit_white,
  Binance,
  Binance_white,
  Ig_white,
  ETH,
  BTC,
  Arrow,
  Dollar,
  Shield,
  Line,
  Arrow_left,
  Arrow_right,
  Close,
  Tooltip,
  Check,
  Stop,
  Play,
  Copy,
  Profile,
  Telegram,
  Key,
  Withdawal,
  Star,
  Edit,
  BTC_STROKE,
  ETH_STROKE,
  Deposit,
  Withdraw,
  History,
  Warning,
  Success,
  Error,
  Membership,
  Upload_img,
  Calendar,
  Withdrawn,
  DepositVolume,
  Trend,
  Submachine,
  Pistol,
  Rifle,
  GrenadeLauncher,
  Flintlock,
  Drone,
  Gatling,
  EyeOpen,
  EyeClose,
  Sort,
  Change,
  DataCenter_News,
  DataCenter_Announcement,
  DataCenter_Chart,
  DataCenter_Diary,
  DataCenter_List,
  DataCenter_BarChart,
  DataCenter_RadarChart,
  DataCenter_UnderConstruction,
  DataCenter_Coin,
  DataCenter_Analytics,
  DataCenter,
  DataCenter_fill,
  Logout,
  Member_Detail,
  Member_Buy,
  Member_Sell,
  Member_Empowerment,
  Member_Earn,
  Member_Value,
  Member_Mindmap,
  Member_Member,
  Member_Member_fill,
  Questhub_Rocket,
  Questhub_Rocket_fill,
  Questhub_Medal,
  Questhub_InProgress,
  Questhub_Completed,
  Link,
  Redirect,
  Tutorial_Teaching,
  Tutorial_Help,
  Shortcut,
  Share,
  Scroll,
  Terms,
  Positive,
  Neutral,
  Negative,
  Unsubscribe,
  Subscribe,
  Point,
};
