import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// --- components ---
// dashboard page
import { GlobalLayout, LandingLayout, ProtectiveLayout } from "./Layout";
// import Loading from './Global/Loading';
// landing page
// import Home from './Landing/Home';
import Login from "./Landing/Login/Login";
import Forgot from "./Landing/Forgot/Forgot";
import Register from "./Landing/Register";
// dashboard page
import Dashboard from "./Dashboard/Main";
import Payment from "./Payment/Payment";
import Mining from "./Mining/Main";
import Memberships from "./Memberships/Memberships";
import AiEntrust from "./AiEntrust/AiEntrust";
import AddAlgo from "./AiEntrust/AddAlgo";
import AddPackage from "./AddPackage/AddPackage";
import Setting from "./Setting/Setting";
import IdentityVerification from "./IdentityVerification/IdentityVerification";
import Withdrawal from "./Setting/OldVersion/Withdrawal";
import TradingView from "./TradingView/TradingView";
import Performance from "./Performance/Main";
// error page
import Errorpage from "./Global/Errorpage";
// testPage
// import UiTest from './Global/UiTest';
import Profile from "./Setting/OldVersion/Profile_v1";

// --- backend --- //
import BackendLogin from "../backend/pages/login/Login";
import BackendLayout from "../backend/layout/Layout";
import BackendDashboard from "../backend/pages/dashboard/Dashboard";
import BackendInvestAhft from "../backend/pages/investAhft/InvestAhft";
import BackendWithdrawal from "../backend/pages/withdrawal/Withdrawal";
import BackendMiningTransfer from "../backend/pages/miningCommitTransfer/MiningCommitTransfer";
import BackendAlgo from "../backend/pages/algo/Algo";
import BackendGridRobot from "../backend/pages/gridRobot/ManageGridRobots";
// ---

// new version
import Layout_v2 from "../v2/components/Layout/Layout";
import NavigateRoute from "../v2/components/Layout/Navigate";

import Login_v2 from "../v2/pages/Login/Login";
import Register_v2 from "../v2/pages/Register/Register";
import ForgotPassword_v2 from "../v2/pages/Forgotpassword/Forgotpassword";
import Setting_v2 from "../v2/pages/Setting/Setting2";
import Algo_v2 from "../v2/pages/Algo/Algo";
import AddAlogo_v2 from "../v2/pages/Algo/AddAlgo2";
import Identity_v2 from "../v2/pages/Identity/Identity";
import Mining_v2 from "../v2/pages/Mining/Mining";
import AddPackage_v2 from "../v2/pages/Mining/AddPackage";
import TransferPackage_v2 from "../v2/pages/Mining/TransferPackage/TransferPackage";
import DataCenter_v2 from "../v2/pages/DataCenter/DataCenter";
import Member_v2 from "../v2/pages/Member/Member";
import QuestHub_v2 from "../v2/pages/QuestHub/QuestHub";
import Invite_v2 from "../v2/pages/Invite/Invite";
import ApiKeyGuide_v2 from "../v2/pages/ApiKeyGuide/ApiKeyGuide";
import ApiKeyBybit_v2 from "../v2/pages/ApiKeyGuide/ApiKeyBybit";
import ApiKeyBinance_v2 from "../v2/pages/ApiKeyGuide/ApiKeyBinance";
import ApiKeyBybitUta_v2 from "../v2/pages/ApiKeyGuide/ApiKeyBybitUta";
import WithdrawalGuide_v2 from "../v2/pages/WithdrawalGuide/WithdrawalGuide";
import TermsOfService_v2 from "../v2/pages/TermsOfService/TermsOfService";

import LandingPage from "./LandingPage/Type/TypeB";
import Chinese from "./LandingPage/Chinese";
import Symbol4h_btc_eth from "./Charts/ChartDashboard";

import ScrollToTop from "../v2/utils/scrollToTop";
import { Fragment } from "react";

export const routes = {
  landing: "/AVA/home",
  ch: "/AVA/ch",
  login: "/AVA/login",
  register: "/AVA/register",
  dashboard: "/AVA/dashboard",
  performance: "/AVA/dashboard/performance",
  payment: "/AVA/dashboard/payment",
  mining: "/AVA/dashboard/mining",
  memberships: "/AVA/dashboard/memberships",
  algotrade: "/AVA/dashboard/algotrade",
  tradingView: "/AVA/dashboard/tradingView",
  addpkg: "/AVA/dashboard/add_package",
  setting: "/AVA/setting/",
  profile: "/AVA/setting/profile",
  identityVerification: "/AVA/setting/identity_verification",
  withdrawAddress: "/AVA/setting/withdraw_address",
  // dev
  ui: "/AVA/uiDev",
  // backend
  backend_login: "/backend_53696746/login",
  backend_dashboard: "/backend_53696746/dashboard",
  backend_investAhft: "/backend_53696746/dashboard/investAhft",
  backend_withdrawal: "/backend_53696746/dashboard/withdrawal",
  backend_mining_commit_transfer:
    "/backend_53696746/dashboard/miningCommitTransfer",
  new_backend_algo: "/backend_algo/list",
  // v2
  login_v2: "/v2/login",
  register_v2: "/v2/register",
  forgotPassword_v2: "/v2/forgot_password",
  setting_v2: "/v2/setting",
  algo_v2: "/v2/algo",
  addalgo_v2: "/v2/addalgo",
  identity_v2: "/v2/setting/identity",
  mining_v2: "/v2/mining",
  addPackage_v2: "/v2/mining/addpackage",
  transferPackage_v2: "/v2/mining/transferpackage",
  dataCenter_v2: "/v2/datacenter",
  member_v2: "/v2/member",
  questhub_v2: "/v2/questhub",
  invite_v2: "/v2/invite",
  ApiKeyGuide_v2: "/v2/apikeyguide/guide",
  ApiKeyBybit_v2: "/v2/apikeyguide/bybit",
  ApiKeyBinance_v2: "/v2/apikeyguide/binance",
  ApiKeyBybitUta_v2: "/v2/apikeyguide/bybituta",
  WithdrawalGuide_v2: "/v2/withdrawalguide/guide",
  TermsOfService_v2: "/v2/termsofservice",
};

export const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      <ScrollToTop />
      <Routes>
        {/* --- backend --- */}
        <Route path="backend_53696746">
          <Route path="dashboard" element={<BackendLayout />}>
            <Route path="investAhft" element={<BackendInvestAhft />} />
            <Route path="withdrawal" element={<BackendWithdrawal />} />
            <Route
              path="miningCommitTransfer"
              element={<BackendMiningTransfer />}
            />
            <Route index element={<BackendDashboard />} />
          </Route>
          <Route path="login" element={<BackendLogin />} />
        </Route>
        {/* --- new backend --- */}
        <Route path="backend_algo">
          <Route path="list" element={<BackendAlgo />} />
          <Route path="gridRobot" element={<BackendGridRobot />} />
        </Route>

        {/* --- v2 --- */}
        <Route path="v2">
          <Route path="login" element={<Login_v2 />} />
          <Route path="" element={<NavigateRoute />}>
            <Route path="" element={<Layout_v2 />}>
              <Route path="setting" element={<Setting_v2 />}>
                <Route path=":verified" element={<Register_v2 />} />
              </Route>
              {/* <Route path="setting" element={<Setting_v2 />} /> */}
              <Route path="algo" element={<Algo_v2 />} />
              <Route path="algo/:tutorial" element={<Algo_v2 />} />
              <Route path="addalgo" element={<AddAlogo_v2 />} />
              <Route path="addalgo/:tutorial" element={<AddAlogo_v2 />} />
              <Route path="setting/identity" element={<Identity_v2 />} />
              <Route path="mining" element={<Mining_v2 />} />
              <Route path="mining/:tutorial" element={<Mining_v2 />} />
              <Route path="mining/addpackage" element={<AddPackage_v2 />} />
              <Route
                path="mining/addpackage/:tutorial"
                element={<AddPackage_v2 />}
              />
              <Route
                path="mining/transferpackage/:GroupId"
                element={<TransferPackage_v2 />}
              />
              <Route path="datacenter" element={<DataCenter_v2 />} />
              <Route path="member" element={<Member_v2 />} />
              <Route path="questhub" element={<QuestHub_v2 />} />
              <Route path="apikeyguide/guide" element={<ApiKeyGuide_v2 />} />
              <Route path="apikeyguide/bybit" element={<ApiKeyBybit_v2 />} />
              <Route
                path="apikeyguide/bybituta"
                element={<ApiKeyBybitUta_v2 />}
              />
              <Route
                path="apikeyguide/binance"
                element={<ApiKeyBinance_v2 />}
              />
              <Route
                path="withdrawalguide/guide"
                element={<WithdrawalGuide_v2 />}
              />
              <Route path="termsofservice" element={<TermsOfService_v2 />} />
            </Route>
          </Route>

          <Route path="invite/:invites" element={<Invite_v2 />} />

          <Route path="invite" element={<Invite_v2 />} />

          <Route path="register" element={<Register_v2 />}>
            <Route path=":emailVerifyCode" element={<Register_v2 />} />
            {/* <Route path=":invites" element={<Register_v2 />} /> */}
          </Route>
          <Route path="forgot_password" element={<ForgotPassword_v2 />} />
        </Route>

        {/* --- dashboard page --- */}
        <Route path={routes.dashboard} element={<ProtectiveLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="performance" element={<Performance />} />
          <Route path="payment" element={<Payment />} />
          {/* <Route path="mining" element={<Mining />} /> */}
          <Route path="mining" element={<Navigate to="/v2/mining" />} />
          {/* <Route path="algotrade" element={<AiEntrust />} /> */}
          <Route path="algotrade" element={<Navigate to="/v2/algo" />} />
          <Route path="add_algo" element={<AddAlgo />} />
          <Route path="memberships" element={<Memberships />}>
            <Route path=":user" element={<Memberships />} />
          </Route>
          <Route path="add_package" element={<AddPackage />}>
            <Route path=":Gid" element={<AddPackage />} />
          </Route>
          <Route path="tradingView" element={<TradingView />} />
        </Route>

        {/* --- setting page --- */}
        <Route path={routes.setting} element={<Navigate to="/v2/setting" />} />
        {/* <Route path={routes.setting} element={<ProtectiveLayout />}>
        <Route index element={<Setting />} />
        <Route path="profile" element={<Profile />} />
        <Route
          path="identity_verification"
          element={<IdentityVerification />}
        />
        <Route path="withdraw_address" element={<Withdrawal />} />
      </Route> */}

        {/* --- landing page --- */}
        {/* already login will navigate to dashboard */}
        <Route
          path="AVA"
          element={<GlobalLayout element={<LandingLayout />} />}
        >
          {/* <Route index element={<Home />} /> */}
          <Route index element={<LandingPage />} />
          <Route path="ch" element={<Chinese />} />
        </Route>
        {/* already login but want landing page */}
        <Route path="AVA/home" element={<LandingLayout />}>
          {/* <Route index element={<Home />} /> */}
          <Route index element={<LandingPage />} />
        </Route>

        <Route path="AVA/Backtest" element={<Symbol4h_btc_eth />} />

        {/* --- no header & footer page --- */}
        <Route path="AVA/login" element={<Navigate to="/v2/login" />} />
        {/* <Route path="AVA/login" element={<GlobalLayout element={<Login />} />} /> */}
        <Route
          path="AVA/forgot"
          element={<GlobalLayout element={<Forgot />} />}
        />
        <Route
          path="AVA/register"
          element={<GlobalLayout element={<Register />} />}
        />

        {/* --- error page --- */}
        <Route path="/" element={<Navigate to="/AVA" />} />
        <Route path="AVA/*" element={<Errorpage />} />
      </Routes>
    </Fragment>
  );
};
