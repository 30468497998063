import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { Fragment, useEffect, useState } from "react";

import { Select } from "../../../global/Selects";

import { useSelector } from "react-redux";
import { selectSystem } from "../../../slices/systemSlice";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import JoyrideComponent from "../../../global/JoyrideComponent";
import { useLocation } from "react-router-dom";
import Lang from "../../../global/Lang";
import Loading from "../../../components/Loading/Loading";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: 100%;
  min-height: 45vh; */
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const PackageWrapper = styled.div`
  /* border: 1px solid red; */
  color: #fcfcfc;
  padding: 20px 0;
`;

const PacakageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  ${RWD_LG(css`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  `)};

  ${RWD_MD(css`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  `)};

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  `)};
`;

const Pacakage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  position: relative;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  cursor: pointer;
  border-radius: 8px;
  border: ${(props) =>
    props.selectPackage
      ? "1px solid #0AECD1"
      : "1px solid rgba(255, 255, 255, 0.15)"};
`;

const PackageImgWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 0;
  height: 100%;
`;

const PacakageImg = styled.div`
  width: min(max(10vw, 100px), 200px);
  height: min(max(10vw, 100px), 200px);
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PacakageImgCover = styled.div`
  width: min(max(8vw, 80px), 150px);
  height: min(max(8vw, 80px), 150px);
  background-color: #151944;
  border-radius: 50% 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PackageImgRateText = styled.div``;

const PacakageImgRate = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;

  span {
    font-size: min(max(0.833vw, 16px), 18px);
    font-weight: lighter;
  }
`;

const PackageImgDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
`;

const PackageImgData = styled.div`
  /* width: 70%; */
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PacakageImgTarget = styled.div``;

const PacakageImgPercentage = styled.div``;

const PacakageInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

const PacakageInfoDay = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  padding-right: 5px;
`;

const PackageInfoUnit = styled.div`
  font-size: min(max(0.833vw, 16px), 18px);
`;

const PacakageName = styled.div`
  padding: 10px 0;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);
`;

const NoticeWrapper = styled.div`
  padding: 25px 0;
  font-size: min(max(0.833vw, 14px), 16px);
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const TabTitle = styled.div`
  padding: 10px 0;
  text-align: left;
  color: #fcfcfc;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* overflow-x: auto; */
`;

const TabContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 60%;
  align-items: baseline;

  ${RWD_SM(css`
    gap: 10px;
    width: 100%;
    flex: 1;
  `)};
`;

const TabItem = styled.div`
  padding: 0.78125vw 1.3vw;
  width: 100%;
  height: 100%;

  text-align: center;
  /* margin-right: 20px; */
  color: #fcfcfc;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  /* white-space: nowrap; */
  border: ${(props) =>
    props.activeTab
      ? props.color
        ? `2px solid ${props.color}`
        : "1px solid #0aecd1"
      : "1px solid rgba(255, 255, 255, 0.15)"};
  font-size: min(max(0.833vw, 13px), 16px);

  ${RWD_MD(css`
    /* width: 150px; */
    padding: 10px 5px;
    text-align: center;
  `)};
`;

const AssetTabItem = styled.div`
  padding: 0.78125vw 1.3vw;

  height: 100%;
  width: ${(props) => `calc( 100% / ${props.length})`};
  /* flex: 1; */

  text-align: center;
  /* margin-right: 20px; */
  color: #fcfcfc;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  /* white-space: nowrap; */
  border: ${(props) =>
    props.activeTab
      ? props.color
        ? `2px solid ${props.color}`
        : "1px solid #0aecd1"
      : "1px solid rgba(255, 255, 255, 0.15)"};
  font-size: min(max(0.833vw, 13px), 16px);

  ${RWD_MD(css`
    /* width: 150px; */
    padding: 10px 5px;
    text-align: center;
    f
  `)};
`;

const TabTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TagWrapper = styled.div`
  padding-top: 10px;
`;

const TagContainer = styled.div``;

const Tag = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TagText = styled.span`
  padding: 2px 10px;
  font-size: clamp(12px, 0.7291vw, 14px);
  text-align: center;
`;

const Nodata = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  padding: 20px;
  /* background-color: #22254a; */
  display: flex;
  color: #54587b;
  /* border: 1px solid red; */
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 30vh;
`;

const SelectPackage = ({
  riskType,
  setRiskType,
  packageData,
  packageId,
  setPackageId,
  setActiveStep,
  setRiskTypeData,
  riskTypeData,
  asset,
  setAsset,
  isLoading,
}) => {
  const selectData = useSelector(selectSystem).enums;

  const { search, pathname } = useLocation();

  const [sortPackageData, setSortPackageData] = useState([]);
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    if (selectData.length > 0) {
      const selectDataList = Object.entries(
        selectData?.filter((list) => list?.GroupName === "PackageRiskType")?.[0]
          ?.Items
      ).map((item) => item[1]);

      const whiteList = selectDataList.map((type) => {
        if (packageData.filter((data) => data.Type === type).length > 0) {
          return type;
        } else {
          return;
        }
      });

      setRiskTypeData(
        Object.entries(
          selectData?.filter(
            (list) => list?.GroupName === "PackageRiskType"
          )?.[0]?.Items
        ).filter(function (event) {
          return whiteList.indexOf(event[1]) > -1;
        })
      );
    }
  }, [selectData, packageData]);

  // 取第一個riskType
  useEffect(() => {
    setRiskType(riskTypeData[0]?.[1]);
  }, [riskTypeData]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    // mining 資料回來後才開始新手引導
    if (tutorial === "mining" && sortPackageData.length > 0) {
      setJoyrideIsRun(true);
    }
  }, [search, sortPackageData]);

  const handleJoyrideCallback = () => {
    setActiveStep(1);
  };
  const [joyrideIsRun, setJoyrideIsRun] = useState(false);

  const joyrideState = [
    {
      target: ".createMining_selectPackage",
      content: (
        <Lang
          datakey="mining_tutorial_2-1"
          defaultText="Here you can select your Mining Package."
        />
      ),
      placement: "auto",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: false,
    },
    {
      target: ".createMining_progress",
      content: (
        <Lang
          datakey="mining_tutorial_2-2"
          defaultText="Here shows the current creation stage of Mining. When you are ready, click 'Next' to proceed to the next step."
        />
      ),
      placement: "top",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
  ];

  // Tutorial

  const backgroundColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
  ];

  const packageTypeColor = [
    {
      id: 1,
      tag: <Lang datakey="medium_risk" defaultText="Medium Risk" />,
      color: "rgba(255, 206, 86, 1)",
    },
    {
      id: 2,
      tag: <Lang datakey="risky" defaultText="Risky" />,
      color: "rgba(255, 99, 132, 1)",
    },
    {
      id: 3,
      tag: <Lang datakey="low_risk" defaultText="Low Risk" />,
      color: "rgba(75, 192, 192, 1)",
    },
  ];

  useEffect(() => {
    setAssetData([
      {
        id: 1,
        name: "USDT",
        value: "USDT",
      },
      {
        id: 2,
        name: "BTC (BETA)",
        value: "BTC",
      },
      {
        id: 3,
        name: "ETH",
        value: "ETH",
      },
    ]);
  }, []);

  useEffect(() => {
    // 過濾符合的riskType

    // packageId 35 36  => ETH
    // packageId  22 35 36 => BTC

    if (asset === "BTC") {
      setSortPackageData(
        packageData.filter(
          (data) =>
            data.Type === riskType &&
            (data.PackageId === 22 ||
              data.PackageId === 35 ||
              data.PackageId === 36)
        )
      );
    } else if (asset === "ETH") {
      setSortPackageData(
        packageData.filter(
          (data) =>
            data.Type === riskType &&
            (data.PackageId === 36 || data.PackageId === 35)
        )
      );
    } else {
      // 如果 asset 為 USDT 且選擇第一個 riskType PackageId = 16 的 Package 要排在第一順位
      if (riskType === riskTypeData?.[0]?.[1]) {
        const filteredPackage = packageData.filter(
          (data) => data.Type === riskType
        );
        const targetPackage = filteredPackage.filter(
          (data) => data.PackageId === 16
        );
        const packages = filteredPackage.filter(
          (data) => data.PackageId !== 16
        );
        setSortPackageData(targetPackage.concat(packages));
      } else {
        setSortPackageData(
          packageData.filter((data) => data.Type === riskType)
        );
      }
    }
  }, [packageData, riskType, asset]);

  const BackgroundColor = (data) => {
    const list = data.map((el) => el.Proportion * 100);
    const percentage = list.map((item, index, array) => {
      let sub = array.slice(0, index + 1);
      let sum = sub.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    });

    return data.map((item, index) => {
      if (data.length > 1) {
        return `${backgroundColor[index]} ${percentage[index - 1] ?? 0}% ${
          percentage[index]
        }%`;
      } else {
        return `${backgroundColor[index]} 0 ${percentage[index]}%`;
      }
    });
  };

  return (
    <Wrapper>
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"mining"}
      />
      <Title>
        <Lang
          datakey="mining_package_selection"
          defaultText="Package Selection"
        />
      </Title>
      {isLoading && <Loading />}

      <TabTitle>
        <Lang
          datakey="mining_package_asset"
          defaultText="Mining Package Asset"
        />
      </TabTitle>
      <TabWrapper>
        <TabContainer>
          {location.pathname.includes("transferpackage")
            ? assetData
                ?.filter((assetValue) => assetValue.value === asset)
                .map((data) => (
                  <AssetTabItem
                    activeTab={data?.value === asset}
                    onClick={() => setAsset(data.value)}
                    key={data.id}
                    length={riskTypeData.length}
                  >
                    {data.name}
                  </AssetTabItem>
                ))
            : assetData?.map((data, index) => (
                <AssetTabItem
                  activeTab={data?.value === asset}
                  onClick={() => setAsset(data.value)}
                  key={data.id}
                >
                  {data.name}
                </AssetTabItem>
              ))}
        </TabContainer>
      </TabWrapper>

      <TabTitle>
        <Lang datakey="mining_package_type" defaultText="Mining package Type" />
      </TabTitle>

      <TabWrapper>
        <TabContainer>
          {riskTypeData?.map((data, index) => (
            <TabTagContainer key={data[1]}>
              <TabItem
                activeTab={data[1] === riskType}
                onClick={() => setRiskType(data[1])}
                color={packageTypeColor[index]?.color}
              >
                {data[0]}
              </TabItem>

              {data[1] === riskType && (
                <TagWrapper>
                  <TagContainer>
                    <Tag bgColor={packageTypeColor[index]?.color}>
                      <TagText>
                        {packageTypeColor[index]?.tag
                          ? packageTypeColor[index]?.tag
                          : ""}
                      </TagText>
                    </Tag>
                  </TagContainer>
                </TagWrapper>
              )}
            </TabTagContainer>
          ))}
        </TabContainer>
      </TabWrapper>

      {/* <InputContainer>
        <Select
          group={"PackageRiskType"}
          title={"Mining package Type"}
          styled={"column"}
          onChangeFun={setRiskType}
          nowValue={riskType}
          defaultValue={1}

        />
      </InputContainer> */}

      <PackageWrapper>
        {sortPackageData.length > 0 ? (
          <PacakageContainer className="createMining_selectPackage">
            {sortPackageData.map((data) => (
              <Pacakage
                key={data.PackageId}
                onClick={() => setPackageId(data.PackageId)}
                selectPackage={data.PackageId === packageId}
              >
                <PacakageInfo>
                  <PacakageName>{data.Name}</PacakageName>
                </PacakageInfo>
                <PackageImgWrapper>
                  <PacakageImg
                    style={{
                      background: `conic-gradient(${BackgroundColor(
                        data?.Components
                      )}) `,
                    }}
                  >
                    <PacakageImgCover>
                      <PackageImgRateText>APY</PackageImgRateText>
                      <PacakageImgRate>
                        {`${(data.Rate * 100).toFixed(2)}`}
                        <span> %</span>
                      </PacakageImgRate>
                    </PacakageImgCover>
                  </PacakageImg>
                  <PackageImgDataWrapper>
                    {data?.Components.map((item, index) => (
                      <PackageImgData key={index}>
                        <PacakageImgTarget
                          style={{ color: backgroundColor[index] }}
                        >
                          {item.Asset}
                        </PacakageImgTarget>
                        <PacakageImgPercentage
                          style={{ color: backgroundColor[index] }}
                        >
                          {`${item.Proportion * 100} % `}
                        </PacakageImgPercentage>
                      </PackageImgData>
                    ))}
                  </PackageImgDataWrapper>
                </PackageImgWrapper>

                <PacakageInfo>
                  <PacakageInfoDay>{data.PeriodDays}</PacakageInfoDay>
                  <PackageInfoUnit>
                    <Lang datakey="mining_days" defaultText="days" /> /{" "}
                    <Lang datakey="mining_period" defaultText="period" />
                  </PackageInfoUnit>
                </PacakageInfo>
              </Pacakage>
            ))}
          </PacakageContainer>
        ) : (
          <Nodata>
            <Lang datakey="nodata" defaultText="No data available" />
          </Nodata>
        )}

        <NoticeWrapper>
          <Lang
            datakey="mining_transfer_package_notice"
            defaultText="The deposit amount will convert to the selected token at the
          distribution. It will be used as deposit of the specific token mining
          equipment."
          />
        </NoticeWrapper>
      </PackageWrapper>
    </Wrapper>
  );
};

export default SelectPackage;
