import styled, { css } from "styled-components";
import { QRCodeCanvas } from "qrcode.react";
import Icons from "../../global/icons";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useEffect } from "react";
import { LangString } from "../../utils";
import { AlertMySwal } from "../../global/MySwal";
import Lang from "../../global/Lang";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  /* padding: 2.088vw 4.166vw; */
  width: 41.66vw;
  max-width: 650px;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const QrocdeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

const QrcodeContainer = styled.div`
  padding: 20px;
  border: 1px solid #151944;
  /* background: #fcfcfc; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  background: #fcfcfc;
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const ContentWrapper = styled.div`
  color: #151944;
`;

const ContentTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 1.3vw;
  display: flex;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;
const Content = styled.div`
  font-size: 1.145vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: min(max(1.145vw, 20px), 22px);

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #151944;
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
`;

const ConfirmButton = styled.button`
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  text-align: center;
  background: #151944;
`;

const ConfirmButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);

  display: inline-flex;
  padding: 0.52vw 1.3vw;
  color: #fcfcfc;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const CopyIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
  }

  /* & path {
    fill: #151944;
  } */

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const ReferenceModal = ({ code, setReferanceModalIsOpen }) => {
  console.log(code);

  const [referenceLink, setReferenceLink] = useState("");

  const [referencePage, setReferencePage] = useState("");

  useEffect(() => {
    setReferenceLink(`${window.location.origin}/v2/register/?invites=${code}`);
    setReferencePage(`${window.location.origin}/v2/invite/?invites=${code}`);
  }, [code]);

  const handleCopy = (type) => {
    // 多國語言
    const CopyResString = LangString("alert_copy", "Copy successful");
    let text;
    if (type === "code") {
      text = code;
    } else {
      text = referenceLink;
    }
    navigator.clipboard.writeText(text).then(() => {
      AlertMySwal({
        title: CopyResString,
        showConfimButton: false,
      });
    });
  };

  const handleNavigate = () => {
    window.open(referencePage, "_blank");

    // const handleSelectTransferPackage = (id) => {
    //   navigate(`transferpackage/${id}`);
    // };
  };

  //   const handleNavigate = () => {
  //     // 創建一個包含查詢參數的URL
  //     const queryParams = new URLSearchParams();
  //     queryParams.append("invites", code);
  //     queryParams.append("invites", code);
  //     const url = `${window.location.origin}/v2/invite?${queryParams.toString()}`;

  //     // 使用 window.open 在新分頁中打開URL
  //     window.open(url, "_blank");
  //   };

  const handleClose = () => {
    setReferanceModalIsOpen(false);
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          <Lang datakey="referral_code" defaultText="Referral Code" />
        </Title>
        <QrocdeWrapper>
          <QrcodeContainer>
            <QRCodeCanvas
              id="qrcode"
              value={referenceLink}
              bgColor={"transparent"}
              fgColor={"#151944"}

              //   size={windowSize[0] * 0.08 < 100 ? 100 : windowSize[0] * 0.08}
            />
          </QrcodeContainer>
        </QrocdeWrapper>
        <ContentWrapper>
          <ContentTitle>
            <Lang datakey="referral_code" defaultText="Referral Code" />
          </ContentTitle>
          <ContentContainer>
            <Content>{code}</Content>
            <CopyIconWrapper onClick={() => handleCopy("code")}>
              <Icons.Copy />
            </CopyIconWrapper>
          </ContentContainer>
        </ContentWrapper>
        <ContentWrapper>
          <ContentTitle>
            <Lang datakey="referral_link" defaultText="Referral Link" />
          </ContentTitle>
          <ContentContainer>
            <Content>{referenceLink}</Content>
            <CopyIconWrapper onClick={() => handleCopy("link")}>
              <Icons.Copy />
            </CopyIconWrapper>
          </ContentContainer>
        </ContentWrapper>
        <ContentWrapper>
          <ContentTitle>
            <Lang datakey="referral_page" defaultText="Referral Page" />
          </ContentTitle>
          <ContentContainer>
            <Content>{referencePage}</Content>
            <CopyIconWrapper onClick={handleNavigate}>
              <Icons.Redirect />
            </CopyIconWrapper>
          </ContentContainer>
        </ContentWrapper>

        <ConfirmButtonWrapper>
          <ConfirmButton onClick={handleClose}>
            <ConfirmButtonText>確認</ConfirmButtonText>
          </ConfirmButton>
        </ConfirmButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default ReferenceModal;
